import React, { useEffect, useState } from 'react';
import "../../styles/iframeStyle.css";
import "../../styles/config.css";
import { useLocation } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import Config from '../Common/config';
import ReactWebChatContainer from '../Common/react-chat/chatContainer';
import { BOT, CODE_GENERATOR, HR_ASSISTANT, HRA, PLEASE_LOGIN_TO_CONTINUE, PROMPT_STUDIO } from '../../constants/constants';

const ChatBotComponent = () => {
const location = useLocation();
const [bot, setBot] = useState();
const [botName, setBotName] = useState('');
const { instance } = useMsal();
const [navUrl,setNavUrl] = useState();

let activeAccount;

if (instance) {
    activeAccount = instance.getActiveAccount();
}

useEffect(() => {
  setNavUrl(location?.state?.url);
  setBotName(location?.state?.botName);
  setBot(location?.state?.bot);
},[location])

useEffect(() => {
    const frameName = BOT;
    console.log("bot", botName);
     if (activeAccount) {
      if (navUrl && botName === CODE_GENERATOR) {
        window.open(navUrl, '_blank');
     } 
     else if (navUrl && botName !== HR_ASSISTANT){
        window.open(navUrl, frameName);
     }
    } 
  }, [navUrl, activeAccount, botName]);

  return (
    <div className='iframe-wrapper'>
        <Config botName={botName} menuItem={PROMPT_STUDIO} path={`/ed.ai/prompt`} state={{ bot, botName }} />
        <hr className='horizontal-line'/>
        <AuthenticatedTemplate>
          {botName !== CODE_GENERATOR && botName !== HR_ASSISTANT && (<iframe title={BOT} name={BOT} className='iframe-container' loading="lazy"/>)}

          {botName !== CODE_GENERATOR && botName === HR_ASSISTANT && (<ReactWebChatContainer bot={bot}/>)} 
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <p>{PLEASE_LOGIN_TO_CONTINUE}</p>
        </UnauthenticatedTemplate>
    </div>
  );
};

export default ChatBotComponent;