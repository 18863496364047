import { useEffect, useState } from "react";
import { Accordion, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../styles/home.css";
import DocSearch from "../../assets/icons/doc-unselected.svg";
import SQLIcon from "../../assets/icons/sql_unselected.svg";
import HRIcon from "../../assets/icons/hr_unselected.svg";
import MortgageIcon from "../../assets/icons/mortgage_unselected.svg";
import TestIcon from "../../assets/icons/test-cases-g.svg";
import PMOIcon from "../../assets/icons/pmo_unselected.svg";
import { AGENTS, ASSISTANT, CPQ, DOCS, DOOR_CONFIG, EXPLORE_EVOKE_TOOLS, HRA, HRA1, HR_ASSISTANT, HR_ASSISTANT_1, MORTGAGE_LOAN_ASSISTANT, NRL, PMO, PMO_ASSISTANT, SEARCH_ASSISTANT, SMART_DOCUMENT_SEARCH, SQLA, SQL_AGENT, TEST, TEST_ASSISTANT } from "../../constants/constants";
import DashboardLink from "../Links/dashboardLink";

const Home = () => {
    const navigate = useNavigate();
    const [searchVal, setSearchVal] = useState('');
    const [url, setUrl] = useState('');

    useEffect(() => {
        const baseURL = process.env.REACT_APP_BOT_BASE_URL;
        setUrl(baseURL);
    },[navigate]);

    return(
        <div className="dashboard-wrapper">
            <div className="search-tool-container">
                <span className="dashboard-header">{EXPLORE_EVOKE_TOOLS}</span>
                <Form.Control className="search-tools-field" type="text" placeholder={SEARCH_ASSISTANT} value={searchVal} onChange={(e) => setSearchVal(e.target.value)}/>
            </div>
          
            <div className="dashboard-container">
                <Accordion defaultActiveKey={["0","1"]} alwaysOpen>
                    {/* <Accordion.Item eventKey={"0"}>
                        <Accordion.Header bsPrefix="homeacc-header">{'Accelerator'}</Accordion.Header>
                        <Accordion.Body bsPrefix='homeacc-body'>
                            <ul className="tool-list">
                                <DashboardLink searchVal={searchVal} url={undefined} secondaryPath={undefined} pathname={"/ed.ai/llm"} botName={'LLM Illustration'} defaultIcon={DocSearch} botInitials={'llm'}/>
                                <DashboardLink searchVal={searchVal} url={undefined} secondaryPath={undefined} pathname={"/ed.ai/cpq"} botName={'CPQ Bot'} defaultIcon={DocSearch} botInitials={'cpq'}/>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey={"0"}>
                        <Accordion.Header bsPrefix="homeacc-header">{ASSISTANT}</Accordion.Header>
                        <Accordion.Body bsPrefix='homeacc-body'>
                            <ul className="tool-list">
                                <DashboardLink searchVal={searchVal} url={undefined} secondaryPath={undefined} pathname={"/ed.ai"} botName={SMART_DOCUMENT_SEARCH} defaultIcon={DocSearch} botInitials={DOCS}/>
                                <DashboardLink searchVal={searchVal} url={url} secondaryPath={'hrbot'} pathname={"/ed.ai/bots"} botName={HR_ASSISTANT} defaultIcon={HRIcon} botInitials={HRA}/>
                                <DashboardLink searchVal={searchVal} url={url} secondaryPath={'hrplus'} pathname={"/ed.ai/bots"} botName={HR_ASSISTANT_1} defaultIcon={HRIcon} botInitials={HRA1}/>
                                <DashboardLink searchVal={searchVal} url={url} secondaryPath={'nrlbot'} pathname={"/ed.ai/bots"} botName={MORTGAGE_LOAN_ASSISTANT} defaultIcon={MortgageIcon} botInitials={NRL}/>
                                <DashboardLink searchVal={searchVal} url={undefined} secondaryPath={undefined} pathname={"/ed.ai/pmo"} botName={PMO_ASSISTANT} defaultIcon={PMOIcon} botInitials={PMO}/>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={"1"}>
                        <Accordion.Header bsPrefix="homeacc-header">{AGENTS}</Accordion.Header>
                        <Accordion.Body bsPrefix='homeacc-body'>
                            <ul className="tool-list">
                                <DashboardLink searchVal={searchVal} url={url} secondaryPath={'agent'} pathname={"/ed.ai/bots"} botName={SQL_AGENT} defaultIcon={SQLIcon} botInitials={SQLA}/>
                                <DashboardLink searchVal={searchVal} url={undefined} secondaryPath={undefined} pathname={"/ed.ai/test"} botName={TEST_ASSISTANT} defaultIcon={TestIcon} botInitials={TEST}/>
                                <DashboardLink searchVal={searchVal} url={undefined} secondaryPath={undefined} pathname={"/ed.ai/doorconfig"} botName={DOOR_CONFIG} defaultIcon={TestIcon} botInitials={CPQ}/>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>     
        </div>
    )
}

export default Home;