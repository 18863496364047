import { React } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from 'prop-types';

const AboutComponent = ({show, close, title, modalBody }) => {

    return(
        <Modal show={show} backdrop="static" centered>
          <Modal.Header closeButton onClick={close}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
  
          <Modal.Body>
             <>{modalBody}</>
          </Modal.Body>
      </Modal>
    )
}

AboutComponent.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func,
  title: PropTypes.string,
  modalBody: PropTypes.func,
};

export default AboutComponent;