import React from 'react';
import PropTypes from 'prop-types';
import RegenerateBtn from '../../../assets/images/regenerate_btn.svg';
import {  Button, Card } from 'react-bootstrap';

const ChatMessenger = ({messages, regenerate}) => {


    return (
        <Card className="h-100 w-100 chat-message-container">
                <Card.Body>
                    <div className='scrollable-response'>
                    {messages?.map((msg, index) => (
                        <div key={index} className={`chat-message ${msg.actor}`}>
                            {typeof msg.payload === 'string' ? (
                                <Card.Text>
                                    <div className='user-icons'>
                                    {msg.actor === 'human' ?
                                    (<div className='user-section'>
                                        <div className='user-msg'>{msg.payload}</div>
                                        <div className="user-badge-icon user-badge-avatar-icon"></div>
                                    </div>) :
                                    (<div className='ai-section'>
                                        <div className="ai-badge-icon ai-badge-avatar-icon"></div>
                                        <div className='ai-msg'>{msg.payload}</div>
                                    </div>)}
                                    </div>
                                </Card.Text>
                            ) : (
                                msg.payload
                            )}
                        </div>
                    ))}
                    </div>
                    <div className='regenerate-btn-wrapper'>
                        <Button type='button' className='regenerate-btn' onClick={regenerate}><img src={RegenerateBtn} alt={'Regenerate'} />Regenerate</Button>
                    </div>
                </Card.Body>
            </Card>
    )
}

ChatMessenger.propTypes = {
    messages: PropTypes.array,
  };

export default ChatMessenger;