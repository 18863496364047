/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import ChatMessenger from './chatMessenger';
import ChatInputSection from './sendMessage';
import '../../../styles/reactChat.css';
import { DEVELOPMENT, HRA, HRA1, NRL, SQLA } from '../../../constants/constants';
import { ColorRing } from 'react-loader-spinner';


const ReactWebChatContainer = ({bot}) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [sessionId, setSessionId] = useState('');
    const [loading, setLoading] = useState(false);

    const handleInputChange = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSend();
        } else {
            setInput(event.target.value);
        }
    };

    const getBotURL = (baseURI, bot) => {
        switch(bot) {
            case HRA: return `${baseURI}/hrbot_api/query`;
            case HRA1: return `${baseURI}/hrbot_api/query`;
            case NRL: return `${baseURI}/hrbot_api/query`;
            case SQLA: return `${baseURI}/hrbot_api/query`;
            default: return ``;
        }
    };

    const regenerateChat = useCallback(() => {
        setInput('');
        setSessionId('');
        setMessages([]);
        if (bot) {
            handleSend();
        } 
    },[bot]);

    const handleSend = useCallback(async () => {
        console.log("input", input);
        console.log('sessionid', sessionId);
        if (input !== '') { 
            const userMessage = { actor: 'human', payload: input };
            setMessages((prevMessages) => [...prevMessages, userMessage]);
        } else {
            setLoading(true);
        }
        setInput('');
        const baseURI = process.env.NODE_ENV === DEVELOPMENT ? process.env.REACT_APP_TEST_BOT_BASE_URL : process.env.REACT_APP_BOT_BASE_URL;
        try {
            const response = await axios({
                method: 'post', 
                data: input === '' ? { prompt: input } : {prompt: input, session_id: sessionId},
                headers: {
                    'Content-Type': 'application/json'
                },
                url: getBotURL(baseURI, bot),
            });
            if (response && response.data) {
                setLoading(false);
                const responseData = response.data.response;
                setSessionId(responseData?.session_id);
                const agentResponse = responseData.output || 'No response received';
                if (agentResponse.length > 0) {
                    const aiMessage = {
                        actor: 'ai',
                        payload: agentResponse,
                    };
                    setMessages((prevMessages) => [...prevMessages, aiMessage]);
                } else {
                    const aiMessage = { actor: 'ai', payload: 'No detail found.' };
                    setMessages((prevMessages) => [...prevMessages, aiMessage]);
                }
            }
           
        } catch (error) {
            setLoading(false);
            console.error("Error occurred while fetching response:",error); // Debug statement
            const errorMessage = { actor: 'ai', payload: 'An error occurred while fetching the response.' };
            setMessages((prevMessages) => [...prevMessages, errorMessage]);
        }
    },[input, bot, sessionId]);

    useEffect(() => {
        regenerateChat();
    },[bot]);

    return(
        <div className="chat-container">
                <div className="chat-response-frame">
                {loading && (<div className="loader"><ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                /></div>)}
                 {!loading && (<ChatMessenger messages={messages} regenerate={regenerateChat}/>)}
                </div>
                <div className="chat-input-frame">
                    <ChatInputSection input={input} handleInputChange={handleInputChange} handleSend={handleSend} />
                </div>
        </div>
    )
}

export default ReactWebChatContainer;