import React from 'react';
import PropTypes from 'prop-types';
import mailImg from '../../../assets/icons/Subtract.svg';
import { Button, Card, Form } from 'react-bootstrap';

const ChatInputSection = ({input, handleInputChange, handleSend}) => {


    return (
        <Card className="w-100">
            <Card.Body>
                <Form className='chat-messenger'>
                    <Form.Group controlId="formPrompt" className='text-input-area'>
                        <Form.Control
                            type="text"
                            value={input}
                            onChange={handleInputChange}
                            placeholder="Type your message..."
                            className='text-input-field'
                            onKeyDown={handleInputChange}
                        />
                        <Button variant="primary" onClick={handleSend} className="send-btn">
                            <img src={mailImg} alt="send chat"/>
                        </Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    )
}

ChatInputSection.propTypes = {
    input: PropTypes.string,
    handleInputChange: PropTypes.func,
    handleSend: PropTypes.func
  };

export default ChatInputSection;