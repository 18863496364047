import { React, useState } from "react";
import { Modal, Form, DropdownButton, Dropdown } from "react-bootstrap";
import PropTypes from 'prop-types';
import { AUTO_UPDATE, DEVELOPMENT_SETTINGS, OCCUPY_ENTIRE_WIDTH, RUN_ON_SAVE, SETTINGS_DROPDOWN_TITLE, WIDE_MODE } from "../../constants/constants";

const SettingsComponent = ({show, close, title, reRun }) => {
  const [runOnSave, setRunOnSave] = useState(false);
  const [wideMode, setWideMode] = useState(false);
  const [theme, setTheme] = useState('light');

  const runWideMode = (wide) => {
    setWideMode(!wide);
  }

  const onSave = (runApp) => {
    setRunOnSave(!runApp);
    reRun();
  }

  const useSystemSettings = () => { setTheme(theme)}

  const useLightMode = () => { setTheme('light')}

  const useDarkMode = () => {setTheme('dark') }

    return(
        <Modal show={show} backdrop="static" centered data-bs-theme={theme}>
          <Modal.Header closeButton onClick={close}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
  
          <Modal.Body>
            <div>
              <h6>{DEVELOPMENT_SETTINGS}</h6>
              <div key={'inline-checkbox-1'} className="settings-checkbox-wrapper">
                  <Form.Check
                      inline
                      label={RUN_ON_SAVE}
                      name={'group-2'}
                      value={wideMode}
                      onChange={() => runWideMode(wideMode)}
                      type='checkbox'
                      className='checkbox-label'
                  />
              </div>
              <p className="settings-checkbox-info-text">{AUTO_UPDATE}</p> 
              <h6>Appearance</h6>
              <div key={'inline-checkbox-2'} className="settings-checkbox-wrapper">
                  <Form.Check
                      inline
                      label={WIDE_MODE}
                      name={'group-3'}
                      value={runOnSave}
                      onChange={() => onSave(runOnSave)}
                      type='checkbox'
                      className='checkbox-label'
                  />
              </div>
              <p className="settings-checkbox-info-text">{OCCUPY_ENTIRE_WIDTH}</p>
                <DropdownButton
                variant="light"
                key={'up'}
                id={`dropdown-button-drop-up`}
                drop={'up'}
                title={SETTINGS_DROPDOWN_TITLE}
              >
                <Dropdown.Item eventKey="1" onClick={useSystemSettings}>Use System Setting</Dropdown.Item>
                <Dropdown.Item eventKey="2"  onClick={useLightMode}>Light</Dropdown.Item>
                <Dropdown.Item eventKey="3"  onClick={useDarkMode}>Dark</Dropdown.Item>
              </DropdownButton>
            </div>
          </Modal.Body>
      </Modal>
    )
}

SettingsComponent.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func,
  title: PropTypes.string,
  reRun: PropTypes.func,
};

export default SettingsComponent;