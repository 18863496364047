import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import '../../styles/reactChat.css';
import '../../styles/cpqchat.css';
import mailImg from '../../assets/icons/send_mail.svg';

const CPQChatComponent = () => {
    const [messages, setMessages] = useState([
        { actor: 'ai', payload: 'Hi! How can I help you?' }
    ]);
    const [productList, setProductList] = useState(undefined);
    const [input, setInput] = useState('');

    const handleInputChange = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSend();
        } else {
            setInput(event.target.value);
        }
    };

    const handleSend = async () => {
        if (!input?.trim()) return;

        const userMessage = { actor: 'user', payload: input };
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        try {
            console.log("Sending request to API with prompt:", input); // Debug statement
            const response = await axios({
                method: 'post', 
                data: { prompt: input },
                headers: {
                    'Content-Type': 'application/json'
                },
                url: `https://dev-egaapi.evokeapps.com/cpq/cpqagent/execute_agent`,
            });
            console.log("Response received from API:", response.data); // Debug statement

            const responseData = response.data.response;
            const agentResponse = responseData?.output || 'No response received';
            console.log("Agent response:", agentResponse); // Debug statement

            // Extract product details from the response
            const products = parseProducts(agentResponse);
            console.log("Extracted products:", products); // Debug statement

            if (products.length > 0) {
                setProductList(products);
                const aiMessage = {
                    actor: 'ai',
                    payload: products,
                };
                setMessages((prevMessages) => [...prevMessages, aiMessage]);
            } else {
                const aiMessage = { actor: 'ai', payload: 'No products found.' };
                setMessages((prevMessages) => [...prevMessages, aiMessage]);
            }
        } catch (error) {
            console.error("Error occurred while fetching response:", error); // Debug statement
            const errorMessage = { actor: 'ai', payload: 'Error occurred while fetching response.' };
            setMessages((prevMessages) => [...prevMessages, errorMessage]);
        }
        setInput('');
    };

    const parseProducts = (response) => {
        const productPattern = /\*\*(.*?)\*\*\s*!\[.*?\]\((.*?)\)/g;
        const products = [];
        let match;
        while ((match = productPattern.exec(response)) !== null) {
            console.log("Matched product:", match); // Debug statement
            products.push({ name: match[1], thumbnail: match[2] });
        }
        return products;
    };

    const renderProducts = (products) => {
        const rows = [];
        for (let i = 0; i < products.length; i += 3) {
            rows.push(products.slice(i, i + 3));
        }
        return (
            <div className='product-list'>
                <Row className='product-row'>
                    <Col className='product-container'>
                        <div className='products-wrapper'>
                            <Card className='border-0'>
                                <Card.Body>       
                                    {rows.map((row, rowIndex) => (
                                    <Row key={rowIndex} className="product-row">
                                        {row.map((product, colIndex) => (
                                            <Col key={colIndex} md={4} className="product-container">
                                                <p>{product.name}</p>
                                                <img src={product.thumbnail} alt={product.name} className="product-image" />
                                            </Col>
                                        ))}
                                    </Row>
                                    ))}
                                </Card.Body>
                            </Card>
                        </div>
                    </Col> 
                </Row>
            </div>
        );
    };

    return (
        <Container fluid className="chat-container">
            <Row className="h-100">
                <header className='py-2 px-3 border border-bottom cpq-header'><h5>CPQ Bot</h5></header>
                <Col md={6} className='border border-right border-top-0'>
                    <div className="chat-cpq-response-frame">
                        <Card className="h-100 w-100 border-0">
                            <Card.Body>
                                {messages.map((msg, index) => (
                                    <div key={index} className={`chat-cpq-message ${msg.actor}`}>
                                         {typeof msg.payload === 'string' ? (
                                            <Card.Text>
                                                <div className='user-icons'>
                                                {msg.actor === 'user' ?
                                                (<div className='user-section'>
                                                    <div className='user-msg'>{msg.payload}</div>
                                                    <div className="user-badge-icon user-badge-avatar-icon"></div>
                                                </div>) :
                                                (<div className='ai-section'>
                                                    <div className="ai-badge-icon ai-badge-avatar-icon"></div>
                                                    <div className='ai-msg'>{msg.payload}</div>
                                                </div>)}
                                                </div>
                                            </Card.Text>
                                    ) : (
                                        msg.payload
                                    )}
                                    </div>
                                ))}
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="chat-input-frame">
                        <Card className="w-100 border-0">
                            <Card.Body>
                                <Form className='chat-messenger'>
                                    <Form.Group controlId="formPrompt" className='text-input-area'>
                                        <Form.Control
                                            type="text"
                                            value={input}
                                            onChange={handleInputChange}
                                            placeholder="Type your message..."
                                            className='text-input-field'
                                            onKeyDown={handleInputChange}
                                        />
                                        <Button variant="transparent" onClick={handleSend} className="send-cpq-btn">
                                            <img src={mailImg} alt="send chat" height='40px' width='40px'/>
                                        </Button>
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
                <Col md={6}>{productList &&  (<>{renderProducts(productList)}</>)}</Col>
            </Row>
        </Container>
    );
};

export default CPQChatComponent;
