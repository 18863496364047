import { React, useState } from 'react';
import '../../styles/config.css';
import PropTypes from 'prop-types';
import settingIcon from '../../assets/icons/settings.svg';
import screencast from '../../assets/icons/screencast.svg';
import printIcon from '../../assets/icons/print.svg';
import infoIcon from '../../assets/icons/info.svg';
import { NavLink } from 'react-router-dom';
import HamburgerIcon from "../../assets/icons/hamburger.svg";
import { ADMINISTRATION_TEXT, HISTORY, INFO, PROMPT_STUDIO, SCREEN_CAST, SETTINGS } from '../../constants/constants';
import SettingsComponent from './settings';
import ScreencastComponent from './screencast';
import AboutComponent from './about';


const Config = ({botName, menuItem, path, state}) => {
    const [showNavbar, setShowNavbar] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [showScreencast, setShowScreencast] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [title, setTitle] = useState('');

    const handleShowNavbar = () => {
      setShowNavbar(!showNavbar)
    }

    const openScreenCast = () => {
      setShowScreencast(true);
      setTitle(SCREEN_CAST);
    }

    const openInfo = () => {
      setShowInfo(true);
      setTitle(INFO);
    }
    const openSettings = () => {
      setShowSettings(true);
      setTitle(SETTINGS);
    }

    const setInfo = () => {
      return (
        <pre>
          <p>Streamlit v1.34.0</p>
          <p>https://streamlit.io</p>
          <p>Copyright 2024 Snowflake Inc. All rights reserved.</p>
        </pre>
      )
    }

    const reRun = () => {}

  return (
    <nav className="config-items">
        <div className='bot-info'><h5>{botName}</h5>{menuItem === PROMPT_STUDIO &&(<span><img className="bot-additional-func" src={infoIcon} alt="information" onClick={openInfo}/></span>)}</div>
        {menuItem === PROMPT_STUDIO && (<div className='bot-settings'>
            <ul className='config-list'>
                <li className='config-list-item'>
                    <img className="bot-additional-func" src={screencast} alt='screen cast' onClick={openScreenCast}/>
                </li>
                <li className='config-list-item'>
                  <img className="bot-additional-func" src={printIcon} alt='print' onClick={window.print}/>
                </li>
                <li className='config-list-item'>
                  <img className="bot-additional-func" src={settingIcon} alt='settings' onClick={openSettings}/>
                </li>
            </ul>
            <SettingsComponent show={showSettings} close={() => setShowSettings(false)} title={title} />
            <ScreencastComponent show={showScreencast} close={() => setShowScreencast(false)} title={title} reRun={reRun}/>
            <AboutComponent show={showInfo} close={() => setShowInfo(false)} title={title} modalBody={setInfo()}/>
        </div>)}
        <div className="menu-config-icon" onClick={handleShowNavbar}>
          <img className="hamburger-menu" src={HamburgerIcon} alt="hamburger-menu"></img>
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`}>
            <ul className='config-list'>
                <li className='config-list-item'>
                    <NavLink to="/default" className='config-list-item-link'>{ADMINISTRATION_TEXT}</NavLink>
                </li>
                <li className='config-list-item'>
                  <NavLink to={{ pathname: path }} state={state} className='config-list-item-link'>{menuItem}</NavLink>
                </li>
                <li className='config-list-item'>
                    <NavLink to="/default" className='config-list-item-link'>{HISTORY}</NavLink>
                </li>
            </ul>
        </div>
    </nav>
  )
}

Config.propTypes = {
  botName: PropTypes.string,
  menuItem: PropTypes.string,
  path: PropTypes.string,
  state: PropTypes.object,
};


export default Config;