import { React,  useCallback,  useEffect,  useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import PropTypes from 'prop-types';
import { ALSO_RECORD_AUDIO, START_RECORDING } from "../../constants/constants";
import { useReactMediaRecorder } from "react-media-recorder";

const ScreencastComponent = ({show, close, title }) => {
  const [recordAudio, setRecordAudio] = useState(false);
  const { startRecording: startRecord, stopRecording: stopRecord } =
    useReactMediaRecorder({ screen: true, audio: recordAudio });

    const startRecording = () => {
      return startRecord();
    }

    const stopRecording = () => {
      return stopRecord();
    };

    const handleEsc = useCallback((event) => {
      if (event.key === 'Escape') {
       stopRecording();
     }
   },[]);

    useEffect(() => {
      window.addEventListener('keyup', handleEsc, false);
  
      return () => {
        window.removeEventListener('keyup', handleEsc, false);
      };
    }, [handleEsc]);

    return(
        <Modal show={show} backdrop="static" centered>
          <Modal.Header closeButton onClick={close}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
  
          <Modal.Body>
            <div>
              <p>This will record a video with the contents of your screen, so you can easily share what you're seeing with others.</p>
                <div key={'inline-checkbox'} className="mb-3">
                  <Form.Check
                      inline
                      label={ALSO_RECORD_AUDIO}
                      name={'group-1'}
                      value={recordAudio}
                      onChange={() => setRecordAudio(!recordAudio)}
                      type='checkbox'
                      className='checkbox-label'
                  />
                </div>
              <p>Press <code className="special-char">Esc</code> any time to stop recording.</p>
            </div>
          </Modal.Body>
  
          <Modal.Footer>
            <Button variant="secondary" onClick={startRecording} className="screencast-btn">{START_RECORDING}</Button>
          </Modal.Footer>
      </Modal>
    )
}

ScreencastComponent.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func,
  title: PropTypes.string,
};

export default ScreencastComponent;