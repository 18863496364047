// src/components/ChatBot.js
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import '../../styles/llmchat.css';
import user from '../../assets/icons/Subtract.svg';

const LLMChatComponent = () => {
    const [messages, setMessages] = useState([
        { actor: 'ai', payload: 'Hi! How can I help you?' }
    ]);
    const [input, setInput] = useState('');
    const hiddenFileInput = useRef(null);
    const [llmInputValue, setLlmInputValue] = useState('');
    const [vectorInputValue, setVectorInputValue] = useState('');
    const [llmLabel, setLLMLabel] = useState([{
        label: 'GPT-4',
        value: 'GPT-4',
        group: 'llm'
    }, {
        label: 'Gemini',
        value: 'Gemini',
        group: 'llm'
    }, {
        label: 'Other LLM',
        value: 'Other LLM',
        group: 'llm'
    }]);

    const [vectorLabel, setVectorLabel] = useState([{
        label: 'Pinecone',
        value: 'Pinecone',
        group: 'vector'
    }, {
        label: 'Chroma',
        value: 'Chroma',
        group: 'vector'
    }, {
        label: 'FAISS',
        value: 'FAISS',
        group: 'vector'
    }]);

    useEffect(() => {
        setLLMLabel([{
            label: 'GPT-4',
            value: 'GPT-4',
            group: 'llm'
        }, {
            label: 'Gemini',
            value: 'Gemini',
            group: 'llm'
        }, {
            label: 'Other LLM',
            value: 'Other LLM',
            group: 'llm'
        }]);

        setVectorLabel([{
            label: 'Pinecone',
            value: 'Pinecone',
            group: 'vector'
        }, {
            label: 'Chroma',
            value: 'Chroma',
            group: 'vector'
        }, {
            label: 'FAISS',
            value: 'FAISS',
            group: 'vector'
        }])
    }, []);


    const handleFile = (file) => {
      setInput(file?.name);
    };

    const handleInputChange = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSend();
        } else {
            setInput(event.target.value);
        }
    };

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = (event) => {
      hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleFileUpload = (event) => {
      const fileUploaded = event.target.files[0];
      handleFile(fileUploaded);
    };

    const handleSend = async () => {
        if (!input?.trim()) return;

        const userMessage = { actor: 'user', payload: input };
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        try {
            console.log("Sending request to API with prompt:", input); // Debug statement
            // const response = await axios.post('https://dev-egaapi.evokeapps.com/cpq/execute_agent', { prompt: input });
            const response = await axios({
                method: 'post', 
                data: { prompt: input },
                headers: {
                    'Content-Type': 'application/json'
                },
                url: `https://dev-egaapi.evokeapps.com/cpq/cpqagent/execute_agent`,
            });
            console.log("Response received from API:", response.data); // Debug statement

            const responseData = response.data.response;
            const agentResponse = responseData.output || 'No response received';
            console.log("Agent response:", agentResponse); // Debug statement

            // Extract product details from the response
            const products = parseProducts(agentResponse);
            console.log("Extracted products:", products); // Debug statement

            if (products.length > 0) {
                const aiMessage = {
                    actor: 'ai',
                    payload: renderProducts(products),
                };
                setMessages((prevMessages) => [...prevMessages, aiMessage]);
            } else {
                const aiMessage = { actor: 'ai', payload: 'No products found.' };
                setMessages((prevMessages) => [...prevMessages, aiMessage]);
            }
        } catch (error) {
            console.error("Error occurred while fetching response:", error); // Debug statement
            const errorMessage = { actor: 'ai', payload: 'Error occurred while fetching response.' };
            setMessages((prevMessages) => [...prevMessages, errorMessage]);
        }
        setInput('');
    };

    const parseProducts = (response) => {
        const productPattern = /\*\*(.*?)\*\*\s*!\[.*?\]\((.*?)\)/g;
        const products = [];
        let match;
        while ((match = productPattern.exec(response)) !== null) {
            console.log("Matched product:", match); // Debug statement
            products.push({ name: match[1], thumbnail: match[2] });
        }
        return products;
    };

    const renderProducts = (products) => {
        const rows = [];
        for (let i = 0; i < products.length; i += 3) {
            rows.push(products.slice(i, i + 3));
        }
        return (
            <Container fluid>
                {rows.map((row, rowIndex) => (
                    <Row key={rowIndex} className="product-row">
                        {row.map((product, colIndex) => (
                            <Col key={colIndex} md={4} className="product-container">
                                <p>{product.name}</p>
                                <img src={product.thumbnail} alt={product.name} className="product-image" />
                            </Col>
                        ))}
                    </Row>
                ))}
            </Container>
        );
    };

    return (
        <div className="llm-chat-container">
            <h6 className='heading'>LLMs-Vector DBs: Combination Illustration</h6>
                <div className='sub-header'><h6 className='sub-header-text'>Choose LLMs and Vector DBs</h6>
                    <div className='radio-input-selection'>
                        <div>
                        <div className='radio-input-label'>LLM:</div>
                            {llmLabel?.map((val, index) => (
                                    <Form.Check
                                        inline
                                        key={val.value}
                                        label={val.label}
                                        name={val.group}
                                        value={val.value}
                                        onChange={() => setLlmInputValue(val.value)}
                                        type='radio'
                                        className={llmInputValue === val.value ? 'radio-label-llm radio-label-llm-checked' : 'radio-label-llm'}
                                        id={`inline-radio-${val.value}`}
                                    />
                                ))}
                        </div>
                        <div>
                            <div className='radio-input-label'>Vector Database:</div>
                            {vectorLabel?.map((val, index) => (
                                <Form.Check
                                    inline
                                    key={val.value}
                                    label={val.label}
                                    name={val.group}
                                    value={val.value}
                                    onChange={() => setVectorInputValue(val.value)}
                                    type='radio'
                                    className={vectorInputValue === val.value ? 'radio-label-vector radio-label-vector-checked' : 'radio-label-vector'}
                                    id={`inline-radio-${val.value}`}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="chat-response-frame">
                    <Card className="h-100 w-100 border-0">
                        <Card.Body>
                            {messages.map((msg, index) => (
                                <div key={index} className={`chat-message ${msg.actor}`}>
                                    {typeof msg.payload === 'string' ? (
                                        <Card.Text>
                                            <div className='user-icons'>
                                            {msg.actor === 'user' ?
                                            (<div className='user-section'>
                                                <div className='user-msg'>{msg.payload}</div>
                                                <div className="user-badge-icon user-badge-avatar-icon"></div>
                                            </div>) :
                                            (<div className='ai-section'>
                                                <div className="ai-badge-icon ai-badge-avatar-icon"></div>
                                                <div className='ai-msg'>{msg.payload}</div>
                                            </div>)}
                                            </div>
                                        </Card.Text>
                                    ) : (
                                        msg.payload
                                    )}
                                </div>
                            ))}
                        </Card.Body>
                </Card>
                </div>
                <div className="chat-input-frame">
                    <Card className="w-100">
                        <Card.Body>
                            <Form className='chat-messenger'>
                                <Form.Group controlId="formPrompt" className='text-input-area'>
                                    <Form.Control
                                        type="text"
                                        value={input}
                                        onChange={handleInputChange}
                                        placeholder="Type your message..."
                                        className='text-input-field'
                                        onKeyDown={handleInputChange}
                                    />
                                    <Button variant="primary" onClick={handleSend} className="send-btn">
                                        <img src={user} alt="send chat"/>
                                    </Button>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
        </div>
    );
};

export default LLMChatComponent;
